import {
    addRevenueAdminAPI,
    getReportRequestAdminAPI,
    confirmReportDeliveryAdminAPI,
    closeTicketAdminAPI,
    getUserListAdminAPI,
    getUserDetailsByIdForAdminAPI,
    revokeUnrevokeUserForAdminAPI,
    getTicketListForAdminAPI,
    getTicketByIdAPI,
    replyTicketAPI,
    sendInviteAPI,
    getAllArtistNamesAdminAPI,
    getWithdrawRequestAdminAPI,
    sentMoneyByAdminAPI,
    getSentInvitationsAdminAPI,
    countMeterAdminAPI,
    checkAwayStatusAPI,
    changeAwayStatusAdminAPI,
    sendSpecificNotificationsAdminAPI,
    uploadAPI,
    updateAlbumArtworkAdminAPI,
    videoUploadAPI,
} from "./api"

export function getSentInvitationsAdmin(data) {
    return new Promise((resolve, reject) => {
        getSentInvitationsAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}
export function getWithdrawRequestAdmin(data) {
    return new Promise((resolve, reject) => {
        getWithdrawRequestAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function sentMoneyByAdmin(data) {
    return new Promise((resolve, reject) => {
        sentMoneyByAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function addRevenueAdmin(data) {
    return new Promise((resolve, reject) => {
        addRevenueAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getReportRequestAdmin(data) {
    return new Promise((resolve, reject) => {
        getReportRequestAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function confirmReportDeliveryAdmin(data) {
    return new Promise((resolve, reject) => {
        confirmReportDeliveryAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function closeTicketAdmin(data) {
    return new Promise((resolve, reject) => {
        closeTicketAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getUserListAdmin(data) {
    return new Promise((resolve, reject) => {
        getUserListAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function revokeUnrevokeUserForAdmin(data) {
    return new Promise((resolve, reject) => {
        revokeUnrevokeUserForAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getUserDetailsByIdForAdmin(data) {
    return new Promise((resolve, reject) => {
        getUserDetailsByIdForAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function getTicketListForAdmin(data) {
    return new Promise((resolve, reject) => {
        getTicketListForAdminAPI(data).then(res => resolve(res)).catch(err => reject(err))
    })
}

export function sendInvite(data) {
    return new Promise((resolve, reject) => {
        sendInviteAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getTicketById(data) {
    return new Promise((resolve, reject) => {
        getTicketByIdAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function replyTicket(data) {
    return new Promise((resolve, reject) => {
        replyTicketAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function getAllArtistNamesAdmin(data) {
    return new Promise((resolve, reject) => {
        getAllArtistNamesAdminAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function countMeterAdmin(data) {
    return new Promise((resolve, reject) => {
        countMeterAdminAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function checkAwayStatus(data) {
    return new Promise((resolve, reject) => {
        checkAwayStatusAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function changeAwayStatusAdmin(data) {
    return new Promise((resolve, reject) => {
        changeAwayStatusAdminAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function updateAlbumArtworkAdmin(data) {
    return new Promise((resolve, reject) => {
        updateAlbumArtworkAdminAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function sendSpecificNotificationsAdmin(data) {
    return new Promise((resolve, reject) => {
        sendSpecificNotificationsAdminAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function upload(data) {
    return new Promise((resolve, reject) => {
        uploadAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}

export function videoUpload(data) {
    return new Promise((resolve, reject) => {
        videoUploadAPI(data).then((res) => resolve(res)).catch(err => reject(err))
    })
}